@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Play:wght@700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    @apply font-Montserrat;
  }

  body {
    background: #f1f1f1;
  }

  h1 {
    @apply text-xl font-medium capitalize;
  }
}

.link {
  @apply p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer cursor-default duration-300 font-medium hover:text-[#bf3419] hover:bg-[#fbf3f1];
}

/* applied directly active class - route match */
.active {
  @apply bg-[#f2d6d1] text-[#bf3419];
}


.bg-gradient {
  background: #8E2DE2;
  background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);
  background: linear-gradient(to right, #4A00E0, #8E2DE2);
}

/* .image1{
  animation: moveY 2s infinite;
} */
.image2 {
  position: absolute;
  top: -110px;
  left: -400px;
  width: 20%;
  opacity: 0.50;
}

.image3 {
  position: absolute;
  top: 0px;
  right: 200px;
  width: 50%;
  opacity: 0.30;
  z-index: -99;
}